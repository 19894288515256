<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Çıkış yapıyoruz lütfen bekleyiniz. 🚀</h2>

        <b-img fluid :src="imgUrl" alt="Coming soon page" />
      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BForm, BFormInput, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/coming-soon.svg")
    };
  },
  created() {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem("userData");
    window.location = "http://www.d7k.com.tr/";
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
